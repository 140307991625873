import { render, staticRenderFns } from "./wasteproductionAdd.vue?vue&type=template&id=26f688c4&"
import script from "./wasteproductionAdd.vue?vue&type=script&lang=js&"
export * from "./wasteproductionAdd.vue?vue&type=script&lang=js&"
import style0 from "./wasteproductionAdd.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports